import '../../App.css';
import { Carousel } from 'react-bootstrap';
import { Clipboard, Archive, Briefcase } from 'react-feather';
import imgBusiness from '../../img/illustrations/business_img.jpg';
import usa_flag from '../../img/illustrations/flags/usa_flag.png';
import chile_flag from '../../img/illustrations/flags/chile_flag.png';
import arg_flag from '../../img/illustrations/flags/arg_flag.png';
import bsasTimes from '../../img/illustrations/buenosAiresTimes.PNG';
import forbes from '../../img/illustrations/forbes.png';
import ipro from '../../img/illustrations/ipro.jpg';

function Body() {

    //alert(document.lastModified);
    return (
        <>
            <section className="bg-white py-10">

                <div className="container px-5">
                    <div className="row gx-5 text-center">
                        <div className="col-lg-4 mb-5 mb-lg-0">
                            <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4"><Archive /></div>
                            <h3>Impuestos</h3>
                            <p className="mb-0">Impuestos personales para quienes trabajaron en los Estados Unidos. (IRS)</p>
                        </div>
                        <div className="col-lg-4 mb-5 mb-lg-0">
                            <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4"><Briefcase /></div>
                            <h3>Empresa</h3>
                            <p className="mb-0">Abrí tu empresa y cuenta bancaria en los Estados Unidos, estés donde estes.</p>
                        </div>
                        <div className="col-lg-4">
                            <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4"><Clipboard /></div>
                            <h3>Invertí</h3>
                            <p className="mb-0">Invertí en el mercado inmobiliario y financiero (S&P 500) en los Estados Unidos. Desde cualquier parte del mundo.</p>
                        </div>
                    </div>
                </div>
                <div class="svg-border-rounded text-light">

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                </div>
            </section>
            <section class="bg-light py-10">
                <div class="container px-5">
                    <div class="row gx-5 align-items-center justify-content-center">
                        <div class="col-md-9 col-lg-6 order-1 order-lg-0" data-aos="fade-right">
                            <div class="content-skewed content-skewed-right"><img class="content-skewed-item img-fluid shadow-lg rounded-3" src={imgBusiness} alt="..." /></div>
                        </div>
                        <div class="col-lg-6 order-0 order-lg-1 mb-5 mb-lg-0" data-aos="fade-left">
                            <div class="mb-5">
                                <h2>Algunas de las personas que ayudamos…</h2>
                                <p class="lead">En primetaxonline.com ayudamos a individuos y empresas.</p>
                            </div>
                            <div class="row gx-5">
                                <div class="col-md-6 mb-4">
                                    <h6>Ciudadanos de los Estados Unidos.</h6>
                                    <p class="mb-2 small">Si sos ciudadano americano mismo viviendo en los Estados Unidos o en el exterior, estas sujeto a declarar impuestos por tus ingresos mundiales. Nos encargamos de todo el proceso y garantizamos los mejores beneficios impositivos.</p>
                                    <a class="small text-arrow-icon" href="/services">
                                        Ver mas
                                        <i data-feather="arrow-right"></i>
                                    </a>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <h6>Impatriados</h6>
                                    <p class="mb-2 small mb-0">Ayudamos a extranjeros que quieren trabajar y/o invertir en los Estados Unidos arreglar todos sus asuntos con las entidades correspondientes.</p>
                                    <a class="small text-arrow-icon" href="/services">
                                    Ver mas
                                        <i data-feather="arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="row gx-5">
                                <div class="col-md-6 mb-4">
                                    <h6>Dueños de empresas</h6>
                                    <p class="mb-2 small mb-0">Asistimos a aquellos empresarios que buscan desarrollar su negocio. Impulsamos su eficiencia mediante contabilidad, diseño web, marketing y más.</p>
                                    <a class="small text-arrow-icon" href="/services">
                                    Ver mas
                                        <i data-feather="arrow-right"></i>
                                    </a>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <h6>Freelancers</h6>
                                    <p class="small mb-0">Contamos con servicios personalizados para aquellos emprendedores que estén buscando dar el siguiente paso.</p>
                                    <a class="small text-arrow-icon" href="/services">
                                    Ver mas
                                        <i data-feather="arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr class="m-0" />
            <section class="bg-light pt-10">
                <div class="container px-5">
                    <div class="text-center mb-5">
                        <h2 >Nuestros Servicios</h2>
                        <p class="lead">Todo lo que necesitas, en un solo lugar.</p>
                    </div>
                    <div class="row gx-5 z-1">
                        <div class="col-lg-4 mb-5 mb-lg-n10" data-aos="fade-up" data-aos-delay="100">
                            <div class="card pricing h-100">
                                <div class="card-body p-5">
                                    <div class="text-center">
                                        <div class="badge bg-light text-dark rounded-pill badge-marketing badge-sm">Impuestos</div>
                                        <div class="pricing-price">
                                            <div className='starts_at_price'></div>
                                            
                                        </div>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Declaración Jurada para el IRS (Formulario 1040/ 1040NR)</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Impuestos para impatriados</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Work and Travel</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Ingreso de intereses y dividendos</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Impuestos para tu empresa en los Estados Unidos</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Tratados internacionales</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-5 mb-lg-n10" data-aos="fade-up">
                            <div class="card pricing h-100">
                                <div class="card-body p-5">
                                    <div class="text-center">
                                        <div class="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">Empresa</div>
                                        <div class="pricing-price">
                                        <div className='starts_at_price'></div>
                                            
                                            <span class="pricing-price-period"></span>
                                        </div>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Contabilidad</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Apertura/ renovación de empresa</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Cuenta bancaria</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Direccion en los Estados Unidos</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Web design (UI/ UX)</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Marketing</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-lg-n10" data-aos="fade-up" data-aos-delay="100">
                            <div class="card pricing h-100">
                                <div class="card-body p-5">
                                <div class="text-center">
                                        <div class="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">Inversiones</div>
                                        <a href='/appointments'><div className='investment_btn_card' >Consulta GRATIS</div></a>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Mercado inmobiliario</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Mercado financiero (S&P 500)</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Asistencia con la VISA</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Planes de inversión y ahorro de corto, mediano y largo plazo</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Inversiones de capital pequeño</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Inversiones de capital grande</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="svg-border-rounded text-dark">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                </div>
            </section>
            <section class="bg-dark py-10">
                <div class="container px-5">
                    <div class="row gx-5 my-10">
                        <div class="col-lg-6 mb-5">
                            <div class="d-flex h-100">
                                <div class="icon-stack flex-shrink-0 bg-teal text-white"><i class="fas fa-question"></i></div>
                                <div class="ms-4">
                                    <h5 class="text-white">¿Que son los impuestos americanos?</h5>
                                    <p class="text-white-50">Si trabajaste en los Estados Unidos, tenes una empresa o sos ciudadano americano, debes presentar una declaración jurada de tus ganancias/ perdidas ante la entidad recaudadora (IRS).</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-5">
                            <div class="d-flex h-100">
                                <div class="icon-stack flex-shrink-0 bg-teal text-white"><i class="fas fa-question"></i></div>
                                <div class="ms-4">
                                    <h5 class="text-white">¿Como puedo saber si los servicios aplican a mi?</h5>
                                    <p class="text-white-50">Si tenes alguna duda, podes fácilmente hablar con uno de nuestros representantes, de forma gratuita y con cero compromiso, para determinar si te podemos asistir en algo.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-5 mb-lg-0">
                            <div class="d-flex h-100">
                                <div class="icon-stack flex-shrink-0 bg-teal text-white"><i class="fas fa-question"></i></div>
                                <div class="ms-4">
                                    <h5 class="text-white">¿Tengo que ir a los Estados Unidos en algún momento?</h5>
                                    <p class="text-white-50">Todos nuestros servicios son online y pueden realizarse desde cualquier parte del mundo. No hay necesidad de ir físicamente. Algunos servicios requieren una dirección y/o representante en los Estados Unidos – los cuales tenemos disponibles.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="d-flex h-100">
                                <div class="icon-stack flex-shrink-0 bg-teal text-white"><i class="fas fa-question"></i></div>
                                <div class="ms-4">
                                    <h5 class="text-white">¿Donde puedo encontrar mas información?</h5>
                                    <p class="text-white-50">Si hay algo que no podes encontrar directamente en nuestro sitio, no dudes en escribirnos, mandar un Whatsapp o agendar una llamada de audio/ video.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-5 justify-content-center text-center">
                        <div class="col-lg-8">
                            <div class="badge bg-transparent-light rounded-pill badge-marketing mb-4">Empeza Ahora</div>
                            <h2 class="text-white">Ahorra tiempo con primetaxonline.com</h2>
                            <p class="lead text-white-50 mb-5">Podemos resolver todos tus asuntos individuales, de negocio y financieros para que puedas enfocar tus energías donde tu creatividad y pasión mas las demandan.</p>
                            <a class="btn btn-teal fw-500" href="/services">Ver listado de servicios y precios</a>
                        </div>
                    </div>
                </div>
                <div class="svg-border-rounded text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                </div>
            </section>
            <section class="bg-white pt-10">
                <div class="container px-5">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div style={{fontSize:'30px'}}>Presentado en</div>
                        </div>
                    </div>
                    <div className='row' style={{paddingTop:'10px',textAlign:'center'}}>
                        <div className='col-lg-6' style={{textAlign:'center'}}>
                            <a href='https://www.perfil.com/noticias/amp/economia/todo-lo-que-tenes-que-saber-sobre-abrir-una-empresa-en-los-estados-unidos.phtml'><div style={{paddingTop:'60px'}}><img height={40} src='https://www.perfil.com/img/logo-perfil-header.png' /></div>
                            <div style={{padding:'15px'}}>Todo lo que tenés que saber sobre abrir una empresa en los Estados Unidos</div>
                            </a>
                        </div>
                        <div className='col-lg-6' style={{textAlign:'center'}}>
                            <a href='https://www.batimes.com.ar/news/economy/tax-benefits-you-should-know-as-an-american-expat-living-in-buenos-aires.phtml'><div style={{paddingTop:'60px'}}><img height={40} src={bsasTimes} /></div>
                            <div style={{padding:'15px'}}>Tax benefits you should know as an American Expat living abroad</div>
                            </a>
                        </div>
                        <div className='col-lg-6' style={{textAlign:'center'}}>
                            <a href='https://www.forbesargentina.com/columnistas/trump-vs-harris-puede-esperar-politica-economica-fiscal-n59595'><div style={{paddingTop:'60px'}}><img height={40} src={forbes} /></div>
                            <div style={{padding:'15px'}}>Trump vs Harris: qué se puede esperar en política económica y fiscal</div>
                            </a>
                        </div>
                        <div className='col-lg-6' style={{textAlign:'center'}}>
                            <a href='https://www.iprofesional.com/impuestos/416584-impuestos-estados-unidos-como-afectara-argentinos-si-gana-donald-trump-o-kamala-harris-elecciones-noviembre-2024'><div style={{paddingTop:'60px'}}><img height={40} src={ipro} /></div>
                            <div style={{padding:'15px'}}>Propuestas impositivas en EE.UU.: cómo afectarán Trump o Harris los negocios de los argentinos</div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section class="bg-white pt-10">
                <div class="container px-5">

                    <div className='row'>
                        <div className="col-lg-6" style={{ zIndex: '1', padding:'5px' , verticalAlign: 'middle' , justifyContent:'center'}}>
                            <a class="card text-decoration-none h-100 lift" href="#!" style={{verticalAlign: 'middle' }}>
                                <div class="card-body py-5">
                                    <div class="align-items-center">
                                        <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${arg_flag})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                                        <div class="ms-4" style={{padding:'10px'}}>
                                            <h5 class="text-primary">Alex B. – Americano viviendo en Argentina</h5>
                                            <p class="card-text text-gray-600" style={{ overflowY: "auto" }}>“Después de muchos años de estrés sobre mis impuestos de los EE.UU mientras vivía en Argentina, finalmente encontré a primetaxonline.com; que me ayudaron a navegar todos los requerimientos y poner mis asuntos en orden. Todo por un precio razonable. Gracias por ayudar a reducir mi nivel de ansiedad!!!”</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6" style={{ zIndex: '1' }}>
                            <div className='row'>
                                <div className='col-lg-12' style={{padding:'5px'}}> 
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                                <div class="card-body py-5">
                                                    <div class="d-flex align-items-center">
                                                        <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${usa_flag})`, backgroundSize: 'cover'}}></div>
                                                        <div class="ms-4">
                                                            <h5 class="text-primary">Nicolas C. – Ciudadano Americano viviendo en los Estados Unidos</h5>
                                                            <p class="card-text text-gray-600" style={{}}>“Proceso muy simple. Fueron muy informativos y me ayudaron en cada paso.”</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                </div>
                                <div className='col-lg-12' style={{padding:'5px'}}>
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                    <div class="card-body py-5">
                                        <div class="d-flex align-items-center">
                                            <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${chile_flag})`, backgroundSize: 'cover'}}></div>
                                            <div class="ms-4">
                                                    <h5 class="text-primary">Barbara S. – Ciudadana americana viviendo en Chile.</h5>
                                                    <p class="card-text text-gray-600" style={{overflowY:"auto"}}>“Vengo haciendo mis impuestos con ellos por muchos años. Altamente recomendado.”</p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12" style={{ zIndex: '1', padding:'5px' , verticalAlign: 'middle' , justifyContent:'center'}}>
                            <a class="card text-decoration-none h-100 lift" href='/comments' style={{verticalAlign: 'middle' }}>
                                <div class="card-body py-5">
                                    <div class="align-items-center" style={{color:'green'}}>
                                        Ver mas
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
                <div class="svg-border-rounded text-light" style={{zIndex:'-1'}}>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                </div>
            </section>
            <section class="bg-light py-10">
                <div class="container px-5 mt-5">
                    <div class="row gx-5 align-items-center">
                        <div class="col-lg-6">
                            <h4>¿Listo para empezar? </h4>
                        </div>
                        <div class="col-lg-6 text-lg-end">
                            <a class="btn btn-primary fw-500 me-3 my-2" href="/contact">Contactanos</a>
                            <a class="btn btn-white fw-500 my-2 shadow" href="/appointments">Agenda tu consulta gratuita</a>
                        </div>
                    </div>
                </div>
            </section>
            <hr class="m-0" />

        </>
    )
}

export default Body;