import { useEffect, useState } from "react";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function ReferralModal(){

  const api_url = 'https://primetaxonline.com:2053';

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');

  const [subscribe, setSubscribe] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 2000);
  }, [0])

  const sendSubscribe = () => {
    setShow(false);
    axios({
      method:'POST',
      url:api_url+'/api/refer',
      data:{
          email:email
      }
  }).then(res => {
  }).catch((error) => { // error is handled in catch block
    if (error.response) { // status code out of the range of 2xx
      console.log("Data :" , error.response.data);
      console.log("Status :" + error.response.status);
    } else if (error.request) { // The request was made but no response was received
      console.log(error.request);
    } else {// Error on setting up the request
      console.log('Error', error.message);
    }
  });
  }

    return(
        <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div><Modal.Title ><div style={{textAlign:'center'}}>Win <b style={{color:'green'}}>U$50</b> by referring someone!</div></Modal.Title></div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
            <div style={{textAlign:'center',fontWeight:'bold',padding:'10px'}}>Who are our services for?</div>
          <div>- US Expats</div>
          <div>- Digital Nomads</div>
          <div>- Entrepreneurs</div>
          <div>- People needing to open an LLC</div>
          <div>- People studying an MBA in the US</div>
          <div>- Foreigners working in the US (Work and Travel)</div>
            </div>
            <div className="col-lg-6">
            <div style={{textAlign:'center',fontWeight:'bold',padding:'10px'}}>Some of our main services</div>
          <div>- TAX preparation (personal and business)</div>
          <div>- LLC creation</div>
          <div>- Software Development</div>
          <div>- Branding</div>
          <div>- Leads finder</div>
          <div>- Marketing</div>
            </div>
          </div>
          
          <div style={{textAlign:'center',fontWeight:'bold',padding:'10px'}}>Win <b style={{color:'green'}}>U$50</b> for every person you refer and purchases one of our services. Simply fill in your email address and we will send you the referral code.</div>
        </Modal.Body>
          <div style={{textAlign:'center',padding:'20px', border:'none'}}>
            @ <input type="text" placeholder="Email" onChange={e => setEmail(e.target.value)} />
            <div style={{padding:'5px'}}>
            <Button variant="primary" disabled={!email} onClick={sendSubscribe}>
            Get referral code
            </Button>
            </div>
            </div>
      </Modal>
        </>
    )
}

export default ReferralModal;