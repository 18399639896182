import { useState } from "react";

const CookieBanner = () => {

    const [cookieBanner, setCookieBanner] = useState(true);

    const close_banner = () => {
        localStorage.setItem('cookieSeen','shown_accepted');
        setCookieBanner(false);
    }

    if(localStorage.getItem('cookieSeen') == 'shown_accepted' || cookieBanner == false){
        return(
            <></>
        )
    }else{
        return(
            <div id="cookie-banner" className="cookie-banner" style={{display:'inline-block'}}>
                <p>By using our website, you agree to our <a href={'https://primetaxonline.com/privacy_policy.html'}>PRIVACY POLICY</a></p>
                <button className="close" onClick={e => close_banner()}>&times;</button>
            </div>
        )
    }

}

export default CookieBanner;