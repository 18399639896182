import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar';
import Header from './Components/Header';
import Body from './Components/Body';
import Footer from './Components/Footer';
import CookieBanner from './Components/CookieBanner';

import NavbarES from './Components/ES/NavbarES';
import HeaderES from './Components/ES/HeaderES';
import BodyES from './Components/ES/BodyES';
import FooterES from './Components/ES/FooterES';
import CookieBannerES from './Components/ES/CookieBannerES';
import SubscribeES from './Components/ES/SubscribeES';

import Wpp from './Components/Wpp';

import Cookies from 'universal-cookie';
import Subscribe from './Components/Subscribe';
import Refer from './Components/Refer';

function App() {

  const cookies = new Cookies();
console.log(cookies.get('lang'));
const userLang = navigator.language || navigator.userLanguage; 
const params = new URLSearchParams(window.location.search);

  if (params.get("GA") == 1) {
    return (
      <div className="App">
        <Refer />
        <Navbar />
        <Header />
        <Body />
        <Footer />
        <CookieBanner />
        <Wpp />
      </div>
    )
  }
  else if (userLang == "es-ES" || userLang == "es" || cookies.get('lang') == "selected_es") {
    return (
      <div className="App">
        <SubscribeES />
        <NavbarES />
        <HeaderES />
        <BodyES />
        <FooterES />
        <CookieBannerES />
        <Wpp />
      </div>
    );
  } else {
    return (
      <div className="App">
        <Subscribe />
        <Navbar />
        <Header />
        <Body />
        <Footer />
        <CookieBanner />
        <Wpp />
      </div>
    );
  }

  
}

export default App;
