import  Navbar  from './Navbar';
import  Footer  from './Footer';
import  Team  from './Team';
import { Carousel } from 'react-bootstrap';
import gonzalo from '../img/illustrations/profiles/gjs_r.jpg';
import cameron from '../img/illustrations/profiles/cameron.jpg';
import sjs from '../img/illustrations/profiles/sjs.JPG';
import jp from '../img/illustrations/profiles/jp.jpg';
import tomas from '../img/illustrations/profiles/tomas.jpg';
import nicolas from '../img/illustrations/profiles/nicolas.jpg';
import team_1 from '../img/illustrations/profiles/team_1.jpg';
import team_2 from '../img/illustrations/profiles/team_2.jpg';
import team_3 from '../img/illustrations/profiles/team_3.jpg';
import team_4 from '../img/illustrations/profiles/team_4.jpg';
import brightsite from '../img/logos/brightsite_logo_r.png';

import Wpp from './Wpp';

function About(){
    return(
<>
    <Navbar />
    <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    
                    <header class="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                        <div class="page-header-ui-content pt-10">
                            <div class="container px-5 text-center">
                                <div class="row gx-5 justify-content-center">
                                    <div class="col-lg-8">
                                        <h1 class="page-header-ui-title mb-3">About our company</h1>
                                        <p class="page-header-ui-text">Our company culture, and how we do things</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="svg-border-rounded text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </header>
                    <section class="bg-white py-10">
                        <div class="container px-5">
                            <div class="row gx-5 justify-content-center">
                                <div class="col-lg-10">
                                    <h2 class="mb-4">Our essence</h2>
                                    <p>We are a team of financial analysts with various years of trajectory in the business. Our clients vary from US inpats/ expats to small firms and businesses. We pride ourselves on our efficient work ethic and client satisfaction. Our main goal is that our clients can focus solely on doing what they love, while we handle the necessary operations to help them move forward and grow.</p>
                                    <hr class="my-5" />
                                    <h4 class="mb-4">
                                        This is what we do
                                    </h4>
                                    <p>Preparation of personal income tax returns for U.S. inpatriates and expatriates around the world. Consulting before, during, and after the process. Handling of any matters regarding US tax officials (IRS). Business solutions from incorporation, bookkeeping, taxes and all necessary operational aspects. Personalized investment plans.</p>
                                    <div class="card bg-light shadow-none">
                                        <div class="card-body">
                                            <h6>Questions you should ask yourself</h6>
                                            <ul class="mb-0">
                                                <li class="text-italic">I’m I focusing all my energy on solely making my business and financials grow? Or am I wasting valuable time on aspects that could be taken care of for me.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <hr class="my-5" />
                                    <h4 class="mb-4">
                                        Our Experience
                                    </h4>
                                    <p class="lead">We have prepared more than 5,000 personal U.S. Income tax returns (Form 1040) and have helped over 300 companies get their paperwork and books in order. We have been in the industry for +8 years and have been building strong and durable relationships with our clients. We’ve assisted clients worldwide in +15 countries.</p>
                                   
                                    <hr class="my-5" />
                                    <h4>Some of the countries where our clients are located.</h4>
                                        <p class="lead">
                                            • United States
                                            • United Kingdom
                                            • Spain
                                            • Greece
                                            • Czech Republic
                                            • Chile
                                            • Uruguay
                                            • Colombia
                                            • Costa Rica
                                            • Argentina
                                            • Mexico
                                            • Vietnam
                                            • Peru
                                            • Paraguay
                                            • Russia
                                            .
                                        </p>
                                    
                                </div>
                            </div>
                        </div>
                        <Team />
                    </section>
                </main>
            </div>
            <Wpp />
            <Footer />
        </div>
    </>
    )
}

export default About;