import Navbar from './NavbarES';
import Footer from './FooterES';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays, addDays, setHours, setMinutes, getDay } from 'date-fns';
import { useState, useEffect } from "react";
import { InfinitySpin } from 'react-loader-spinner';
import axios from 'axios';
import Wpp from '../Wpp';

function Appointments() {

    const [startDate, setStartDate] = useState(addDays(new Date(), 1)
        //setHours(setMinutes(new Date(), 0), 9)
    );
    const [scheduleBtn, setScheduleBtn] = useState(
        "Schedule now"
    );
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [loadingBackground, setLoadingBackground] = useState('');
    const [name, setName] = useState('');
    const [lName, setLName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [selector, setSelector] = useState('');
    const [text, setText] = useState('');
    const [time, setTime] = useState('9.00 AM');

    const api_url = 'https://primetaxonline.com:2053';


    function ScheduleBtn() {
        console.log(startDate);
        if (loading == false && emailSent == false) {
            return (
                <button class="btn btn-primary fw-500" onClick={(e) => send()} style={{ backgroundColor: loadingBackground, border: 'none', padding: '10px', borderRadius: '10px' }}  disabled={!loading.load == false || !name || !lName || !phone || !email} type="submit">Reservar ahora</button>
            )
        } else if(emailSent == true && loading == false){
            return(
                <div style={{textAlign:'center', color:'green'}}>Su reserva fue confirmada. Por favor revise su correo electronico para ver la confirmacion. Gracias!</div>
            )
        }
         else if(loading == true) {
            return (
                <InfinitySpin color="#4fa94d" />
            )
        }

    }

    const send = () => {

        setLoading(true);

        axios({
            method:'POST',
            url:api_url+'/api/send_appointment',
            data:{
                name,
                lName,
                phone,
                email,
                selector,
                text,
                startDate,
                time
            }
        }).then(res => {
            console.log("Email status "+res.status);
            if(res.status = 200){
                setLoading(false);
                setEmailSent(true);
            }else{
                setLoading(false);
                alert("There was a problem booking the appointment. Please try again. Thank you.");
            }
            
        });

    }



    useEffect(() => {



    }, [startDate])

    const isWeekday = (date) => {
        const day = getDay(date);
        return day !== 0 && day !== 7;
    };

    return (
        <>
            <Navbar />
            <div id="layoutDefault">
                <div id="layoutDefault_content">
                    <main>

                        <header class="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                            <div class="page-header-ui-content">
                                <div class="container px-5">
                                    <div class="row gx-5 align-items-center">
                                        <div class="col-lg-6">
                                            <div class="badge badge-marketing rounded-pill bg-secondary-soft text-secondary mb-3">Empeza hoy!</div>
                                            <h1 class="page-header-ui-title">Reserva una llamada gratis y te contamos cuales son las mejores soluciones para vos o tu empresa.</h1>
                                            <p class="page-header-ui-text">Nuestros expertos contestaran cualquier pregunta o duda que puedas tener y te daran la mejor solucion.</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="card rounded-3 text-dark">
                                                <div class="card-header py-4">Reserva ahora la llamada gratuita con un experto!</div>
                                                <div class="card-body">

                                                    <div class="row gx-5 mb-3">
                                                        <div class="col-md-6">
                                                            <label class="small text-gray-600" for="leadCapFirstName">Nombre</label>
                                                            <input class="form-control" id="leadCapFirstName" type="text" onChange={e => setName(e.target.value)} />
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label class="small text-gray-600" for="leadCapLastName">Apellido</label>
                                                            <input class="form-control" id="leadCapLastName" type="text" onChange={e => setLName(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div class="mb-3">
                                                        <label class="small text-gray-600" for="leadCapCompany">Telefono</label>
                                                        <input class="form-control" id="leadCapCompany" type="text" onChange={e => setPhone(e.target.value)} />
                                                    </div>
                                                    <div class="mb-4">
                                                        <label class="small text-gray-600" for="leadCapEmail">Correo electronico</label>
                                                        <input class="form-control" id="leadCapEmail" type="email" onChange={e => setEmail(e.target.value)} />
                                                    </div>
                                                    <div class="mb-4">
                                                        <label class="small text-gray-600" for="leadCapSelector">Service (select one)</label>
                                                        <select class="form-control" id="leadCapSelector" type="text" onChange={e => setSelector(e.target.value)}>
                                                            <option>Impuestos</option>
                                                            <option>Negocios</option>
                                                            <option>Tecnologia</option>
                                                        </select>
                                                    </div>
                                                    <div class="mb-4">
                                                        <label class="small text-gray-600" for="leadCapText">Comentarios</label>
                                                        <textarea class="form-control" id="leadCapText" type="text" onChange={e => setText(e.target.value)} />
                                                    </div>
                                                    <div class="mb-4">
                                                        <row>

                                                            <div className='col-sm-12 col-md-12 col-lg-12' style={{ textAlign: 'center' }}>
                                                                <DatePicker showIcon
                                                                    selected={startDate}
                                                                    timeFormat="HH:mm"
                                                                    //timeIntervals={30}
                                                                    //showTimeSelect
                                                                    calendarStartDay={2}
                                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                                    filterDate={isWeekday}
                                                                    inline
                                                                    minTime={setHours(0, 9)}
                                                                    maxTime={setHours(30, 20)}
                                                                    onChange={(date) => setStartDate(date)}
                                                                    minDate={subDays(new Date(), 0)}
                                                                    selectsDisabledDaysInRange
                                                                    />
                                                            </div>
                                                            <div className='col-sm-12 col-md-12 col-lg-12' style={{ textAlign: 'center' }}>
                                                                <select onChange={e => setTime(e.target.value)} style={{ textAlign: 'center', borderRadius:'2px', outline: 'none', border:'none', cursor:'pointer' }}>
                                                                    <option value={'09.00 AM'}>9:00EST</option>
                                                                    <option value={'09.30 AM'}>9:30EST</option>
                                                                    <option value={'10.00 AM'}>10:00EST</option>
                                                                    <option value={'10.30 AM'}>10:30EST</option>
                                                                    <option value={'11.00 AM'}>11:00EST</option>
                                                                    <option value={'11.30 AM'}>11:30EST</option>
                                                                    <option value={'01.00 PM'}>13:00EST</option>
                                                                    <option value={'01.30 PM'}>13:30EST</option>
                                                                    <option value={'02.00 PM'}>14:00EST</option>
                                                                    <option value={'02.30 PM'}>14:30EST</option>
                                                                    <option value={'03.00 PM'}>15:00EST</option>
                                                                    <option value={'03.30 PM'}>15:30EST</option>
                                                                    <option value={'04.00 PM'}>16:00EST</option>
                                                                    <option value={'04.30 PM'}>16:30EST</option>
                                                                    <option value={'05.00 PM'}>17:00EST</option>
                                                                    <option value={'05.30 PM'}>17:30EST</option>
                                                                    <option value={'06.00 PM'}>18:00EST</option>
                                                                    <option value={'06.30 PM'}>18:30EST</option>
                                                                    <option value={'07.00 PM'}>19:00EST</option>
                                                                </select>
                                                            </div>
                                                        </row>

                                                    </div>

                                                    <div class="" style={{textAlign:'center'}}><ScheduleBtn /></div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="svg-border-angled text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="currentColor"><polygon points="0,100 100,0 100,100"></polygon></svg>
                            </div>
                        </header>
                        <section class="bg-white border-bottom py-10">
                            <div class="container px-5">
                                <div class="row gx-5 align-items-center">
                                    <div class="col-lg-6 mb-4 mb-lg-0">
                                        <h4 class="text-primary">Dudas?</h4>
                                        <p class="lead mb-0">Hablemos!</p>
                                    </div>
                                    <div class="col-lg-6 text-lg-end">
                                        <a class="btn btn-primary fw-500 me-2" href="/contact">Ponerse en contacto</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>

            </div>
            <Wpp />
            <Footer />
        </>

    )
}

export default Appointments;