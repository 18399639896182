import  Navbar  from './NavbarES';
import  Footer  from './FooterES';
import { Carousel } from 'react-bootstrap';
import gonzalo from '../../img/illustrations/profiles/gjs_r_2.jpg';
import cameron from '../../img/illustrations/profiles/cameron_4.jpg';
import sjs from '../../img/illustrations/profiles/sjs_2.jpg';
import sol from '../../img/illustrations/profiles/sol.jpg';
import jls from '../../img/illustrations/profiles/jls_2.jpg';
import jp from '../../img/illustrations/profiles/jp.jpg';
import tomas from '../../img/illustrations/profiles/tomas_2.jpg';
import nicolas from '../../img/illustrations/profiles/nicolas_2.jpg';
import team_1 from '../../img/illustrations/profiles/team_1.jpg';
import team_2 from '../../img/illustrations/profiles/team_2.jpg';
import team_3 from '../../img/illustrations/profiles/team_3.jpg';
import team_4 from '../../img/illustrations/profiles/team_4.jpg';
import brightsite from '../../img/logos/brightsite_logo_r.png';

function Team(){
    return(
    <>
    <h1 class="mb-4" style={{textAlign:'center',padding:'50px'}}>Conoce al equipo</h1>
                        <div class="container px-5">
                            <div class="row gx-5">
                                <div class="col-md-6 col-xl-4 mb-5">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <img class="card-team-img mb-3" src={gonzalo} alt="..." />
                                            <div class="card-team-name">Gonzalo Serravalle</div>
                                            <div class="card-team-position mb-3">Chief Executive Officer (CEO)</div>
                                            <p class="small mb-0">Liderar la dirección estratégica de la empresa de impuestos, garantizar el cumplimiento normativo, optimizar las estrategias fiscales, impulsar la innovación y mejorar la satisfacción del cliente para maximizar el crecimiento organizacional.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                            <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4 mb-5">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <img class="card-team-img mb-3" src={sjs} alt="..." />
                                            <div class="card-team-name">Santiago Serravalle</div>
                                            <div class="card-team-position mb-3">Chief Financial Officer (CFO)</div>
                                            <p class="small mb-0">Responsable de la gestión financiera estratégica y de las iniciativas de externalización, garantizando una asignación óptima de recursos y eficiencia de costos para impulsar el crecimiento y la rentabilidad de la empresa.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                            <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4 mb-5">
                                    <div class="card card-team">
                                        <div class="card-body">
                                        <div class="card-team-img mb-3" style={{ backgroundImage: `url(${jls})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                                            <div class="card-team-name">Jose Serravalle</div>
                                            <div class="card-team-position mb-3">Chief Operating Officer (COO)</div>
                                            <p class="small mb-0">Supervisando el desarrollo y la gestión de alianzas estratégicas y asociaciones, impulsando sinergias y oportunidades de crecimiento para mejorar la efectividad operativa y la posición en el mercado.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                             <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4 mb-5">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <div class="card-team-img mb-3" style={{ backgroundImage: `url(${sol})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                                            <div class="card-team-name">Sol Serravalle</div>
                                            <div class="card-team-position mb-3">Manager</div>
                                            <p class="small mb-0">Gestionar las relaciones con los clientes, garantizar soluciones fiscales personalizadas, ofrecer un servicio excepcional, resolver consultas y liderar al equipo para entregar resultados precisos y puntuales que superen las expectativas de los clientes.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                             <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4 mb-5 mb-xl-0">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <img class="card-team-img mb-3" src={jp} alt="..." />
                                            <div class="card-team-name">Juan P.</div>
                                            <div class="card-team-position mb-3">Cloud Engineer</div>
                                            <p class="small mb-0">Juan es un profesional de IT dedicado y especializado en arquitectura cloud y servidores.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                        <a href='https://brightsite.dev'><img src={brightsite} /></a> <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4 mb-5 mb-md-0">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <img class="card-team-img mb-3" src={tomas} alt="..." />
                                            <div class="card-team-name">Tomas F.</div>
                                            <div class="card-team-position mb-3">Inversiones</div>
                                            <p class="small mb-0">Si estas buscando invertir en los Estados Unidos, sea en el mercado inmobiliario o el financiero, Tomas y su equipo se asegurarán que tu dinero rinda de la mejor manera posible – mitigando los riesgos y adaptando un plan de inversión de acuerdo a las necesidades y personalidad del cliente.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                            <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <img class="card-team-img mb-3" src={nicolas} alt="..." />
                                            <div class="card-team-name">Nicolas M.</div>
                                            <div class="card-team-position mb-3">Comercial</div>
                                            <p class="small mb-0">Satisfacción del cliente, comunicaciones y marketing son solo algunas de las cualidades que posee Nicolas. A cargo de las plataformas sociales, artículos y analíticas. Lidera las comunicaciones para asegurarse que nuestros pilares funcionen de manera harmoniosa.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                            <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div className='row' style={{padding:'40px'}}>
                                    <div className='col-lg-6 col-md-12 col-sm-12' style={{paddingTop:'5px'}}>
                                        <Carousel fade={true} controls={false} indicators={false}>
                                            <Carousel.Item>
                                                <img style={{objectFit: 'cover',width:'100%'}} src={team_1} />
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img style={{objectFit: 'cover',width:'100%'}} src={team_2} />
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                    <div className='col-lg-6 col-lg-6 col-md-12 col-sm-12' style={{paddingTop:'5px'}}>
                                        <Carousel fade={true} controls={false} indicators={false}>
                                            
                                            <Carousel.Item>
                                                <img style={{objectFit: 'cover',width:'100%'}} src={team_3} />
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img style={{objectFit: 'cover',width:'100%'}} src={team_4} />
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                        </div>
    </>
        
    )
}

export default Team;