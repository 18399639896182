import { BrowserRouter as Router, Route, Routes, Link, useSearchParams } from 'react-router-dom';

import  App  from './App';
import  Contact  from './Components/Contact';
import  Services  from './Components/Services';
import  About  from './Components/About';
import  Team  from './Components/Team';
import  Appointments  from './Components/Appointments';
import  Comments  from './Components/Comments';
import  Outsourcing  from './Components/Outsourcing';
import  Calculator  from './Components/Calculator';

import  ContactES  from './Components/ES/ContactES';
import  ServicesES  from './Components/ES/ServicesES';
import  AboutES  from './Components/ES/AboutES';
import  TeamES  from './Components/ES/TeamES';
import  AppointmentsES  from './Components/ES/AppointmentsES';
import  CommentsES  from './Components/ES/CommentsES';

import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

function Main() {

const cookies = new Cookies();
console.log(cookies.get('lang'));
const userLang = navigator.language || navigator.userLanguage; 

  if(userLang=="es-ES" || userLang=="es" || cookies.get('lang')=="selected_es"){
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} exact />
                <Route path="/contact" element={<ContactES />} exact />
                <Route path="/services" element={<ServicesES />} exact />
                <Route path="/about" element={<AboutES />} exact />
                <Route path="/team" element={<TeamES />} exact />
                <Route path="/appointments" element={<AppointmentsES />} exact />
                <Route path="/comments" element={<CommentsES />} exact />
                <Route path="/calculator" element={<Calculator />} exact />
            </Routes>
        </Router>
    );
  }else{
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} exact />
                <Route path="/contact" element={<Contact />} exact />
                <Route path="/services" element={<Services />} exact />
                <Route path="/about" element={<About />} exact />
                <Route path="/team" element={<Team />} exact />
                <Route path="/appointments" element={<Appointments />} exact />
                <Route path="/outsourcing" element={<Outsourcing />} exact />
                <Route path="/comments" element={<Comments />} exact />
                <Route path="/calculator" element={<Calculator />} exact />
            </Routes>
        </Router>
    );
  }

}

export default Main;