import irs_img from '../../img/illustrations/irs_img.png';
import cdb from '../../img/illustrations/cdb.jpg';
import efile from '../../img/illustrations/efile.png';

function Footer() {
    return (
        <div id="layoutDefault_footer">
            <footer className="footer pt-10 pb-5 mt-auto bg-dark footer-dark">
                <div className="container px-5">
                    <div className="row gx-5">
                        <div className="col-lg-3">
                            <div className="footer-brand">Primetaxonline.com</div>
                            <div className="icon-list-social mb-5">
                                <a className="icon-list-social-link" href="https://www.instagram.com/primetaxonline/"><i className="fab fa-instagram"></i></a>
                                <a className="icon-list-social-link" href="https://www.linkedin.com/company/primetaxonline-com/"><i className="fab fa-linkedin"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row gx-5">
                                <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                                    <div className="text-uppercase-expanded text-xs mb-4">Producto</div>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-2"><a href="/services">Impuestos</a></li>
                                        <li className="mb-2"><a href="/services">Negocios</a></li>
                                        <li className="mb-2"><a href="/services">Inversiones</a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                                    <div className="text-uppercase-expanded text-xs mb-4">Nuestras Oficinas</div>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-2">Estados Unidos: <div>100 sw 10th st, Miami, FL 33130</div></li>
                                        <li className="mb-2">Argentina: <div>Dardo Rocha 1046, Oficina 4, San Isidro, Provincia de Buenos Aires</div></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="text-uppercase-expanded text-xs mb-4">Legal</div>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-2"><a href="https://primetaxonline.com/privacy_policy.html">Política de Privacidad</a></li>
                                        <li className="mb-2"><a href="#!">Términos y Condiciones</a></li>
                                        <li><a href="#!">Licencia</a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-5 mb-md-0">
                                    <div className="text-uppercase-expanded text-xs mb-4">Contacto</div>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-2">info@primetaxonline.com</li>
                                        <li className="mb-2">+54 11 3655-5405</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-5" />
                    <div className="row gx-5 align-items-center">
                        <div className="col-md-4 small">Derechos de Autor &copy; primetaxonline.com 2023</div>
                        <div className="col-md-4 small">Desarrollado por <b><a href="https://brightsite.dev">brightsite.dev</a></b></div>
                        <div className="col-md-4 text-md-end small">
                            <a href="https://primetaxonline.com/privacy_policy.html">Política de Privacidad</a>
                            &middot;
                            <a href="#!">Términos y Condiciones</a>
                        </div>
                    </div>
                </div>
                
                {/* Certificate Images Section with Full-Width White Background */}
                <div style={{ backgroundColor: 'white', padding: '20px 0', marginTop:'10px', paddingTop:'20px' }}>
                    <div className="container">
                        <div className="d-flex justify-content-center align-items-center">
                            <img src={irs_img} alt="Certificado IRS" className="img-fluid" style={{ maxWidth: '100px', margin: '0 10px' }} />
                            <img src={cdb} alt="Certificado CDB" className="img-fluid" style={{ maxWidth: '100px', margin: '0 10px' }} />
                            <img src={efile} alt="Certificado Efile" className="img-fluid" style={{ maxWidth: '100px', margin: '0 10px' }} />
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
